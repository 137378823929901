<template>
  <div class="main-box">
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
      <div v-if="step1">
        <div class="ec-box">
          <div class="title">
            <p>企业认证-企业信息</p>
          </div>
          <div class="content">
            <div class="con-item">
              <a-form-item label="企业名称" name="enterpriseName">
                <a-input
                  v-model:value="enterpriseName"
                  :disabled="isAuth === 'Y' ? true : false"
                  placeholder="请输入企业名称"
                />
              </a-form-item>
            </div>
            <div class="con-item">
              <a-form-item label="统一社会信用代码" name="creditCode">
                <a-input
                  v-model:value="creditCode"
                  :disabled="isAuth === 'Y' ? true : false"
                  placeholder="请输入统一社会信用代码"
                />
              </a-form-item>
            </div>
            <div class="con-item">
              <a-form-item label="企业类型" name="hangyeleixing">
                <a-select
                  @change="onHangyeleixing"
                  v-model:value="hangyeleixing"
                  placeholder="请选择企业类型"
                  :disabled="isAuth === 'Y' ? true : false"
                >
                  <a-select-option
                    v-for="(iItem, ii) in industryType"
                    :key="ii"
                    v-model:value="iItem.BIANMA"
                  >
                    {{ iItem.NAME }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </div>
            <!--                        组织形式改行业类别-->
            <div class="con-item">
              <a-form-item label="行业类型" name="hangyeleibie">
                <a-select
                  @change="hangyeleibie"
                  v-model:value="hangyeleibie"
                  placeholder="请选择行业类型"
                  :disabled="isAuth === 'Y' ? true : false"
                >
                  <a-select-option
                    v-for="(qItem, qi) in hylb"
                    :disabled="isAuth === 'Y' ? true : false"
                    :key="qi"
                    v-model:value="qItem.DICTIONARIES_ID"
                    >{{ qItem.NAME }}
                  </a-select-option>
                </a-select>
                <!--                                <a-input  v-model:value="compositionForm" :disabled="isAuth === 'Y' ? true : false" placeholder="请输入行业类别" />-->
              </a-form-item>
            </div>
            <div class="con-item">
              <a-form-item label="登记机关" name="legalOrg">
                <a-input
                  v-model:value="legalOrg"
                  :disabled="isAuth === 'Y' ? true : false"
                  placeholder="请输入登记机关"
                />
              </a-form-item>
            </div>
            <div class="con-item">
              <a-form-item label="登记机关所在区域" name="area">
                <a-cascader
                  v-model:value="area"
                  :disabled="isAuth === 'Y' ? true : false"
                  :options="options"
                  placeholder="请选择登记机关所在区域"
                />
              </a-form-item>
            </div>
            <div class="con-item">
              <a-form-item label="行业归属" name="qiyeleixing">
                <a-select
                  @change="onQiyeleixing"
                  v-model:value="qiyeleixing"
                  placeholder="请选择行业归属"
                  :disabled="isAuth === 'Y' ? true : false"
                >
                  <a-select-option
                    v-for="(qItem, qi) in enterpriseType"
                    :disabled="isAuth === 'Y' ? true : false"
                    :key="qi"
                    v-model:value="qItem.BIANMA"
                    >{{ qItem.NAME }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </div>
            <div class="con-item">
              <a-form-item label="经营场所" name="businessPlace">
                <a-input
                  v-model:value="businessPlace"
                  :disabled="isAuth === 'Y' ? true : false"
                  placeholder="请输入经营场所"
                />
              </a-form-item>
            </div>
            <div class="con-item">
              <a-form-item label="经营范围及方式">
                <a-textarea
                  v-model:value="businessScope"
                  :disabled="isAuth === 'Y' ? true : false"
                  placeholder="请输入经营范围及方式"
                  :auto-size="{ minRows: 5, maxRows: 8 }"
                />
                <!--                                <a-input  v-model:value="businessScope" :disabled="isAuth === 'Y' ? true : false" placeholder="请输入经营范围及方式" />-->
              </a-form-item>
            </div>
            <div class="con-item">
              <a-form-item label="是否具有抵质押物">
                <!--                <a-input v-model:value="collateral" :disabled="isAuth === 'Y' ? true : false"-->
                <!--                         placeholder="请输入是否具有抵质押物"/>-->

                <a-select
                  v-model:value="collateral"
                  :disabled="isAuth === 'Y' ? true : false"
                  allowClear="true"
                  placeholder="请输入是否具有抵质押物"
                >
                  <a-select-option value="是">是</a-select-option>
                  <a-select-option value="否">否</a-select-option>
                </a-select>
              </a-form-item>
            </div>
            <div class="con-item">
              <a-form-item label="抵押物简述">
                <a-input
                  v-model:value="collaRemark"
                  :disabled="isAuth === 'Y' ? true : false"
                  placeholder="请输入抵押物简述"
                />
              </a-form-item>
            </div>
            <div class="con-item">
              <a-form-item label="注册资本(万元)" name="capital">
                <a-input-number
                  style="width: 460px !important;"
                  v-model:value="capital"
                  :disabled="isAuth === 'Y' ? true : false"
                  placeholder="请输入注册资本"
                />
              </a-form-item>
            </div>
            <!--            <div class="con-item">-->
            <!--              <a-form-item label="企业荣誉">-->
            <!--                <a-select v-model:value="emphasis" :disabled="isAuth === 'Y' ? true : false"-->
            <!--                          allowClear="true"-->
            <!--                          mode="tags"-->
            <!--                          placeholder="企业荣誉影响贷款额度，请谨慎选择，如下拉列表没有可自定义">-->
            <!--                  <a-select-option value="高新技术企业">高新技术企业</a-select-option>-->
            <!--                  <a-select-option value="专精特新企业">专精特新企业</a-select-option>-->
            <!--                  <a-select-option value="绿色信贷企业">绿色信贷企业</a-select-option>-->
            <!--                  <a-select-option value="小巨人企业">小巨人企业</a-select-option>-->
            <!--                  <a-select-option value="稳企稳岗企业"> 稳企稳岗企业</a-select-option>-->
            <!--                </a-select>-->
            <!--              </a-form-item>-->
            <!--            </div>-->

            <div class="con-item">
              <a-form-item label="企业荣誉">
                <template v-for="(tag, index) in tags" :key="tag">
                  <a-tooltip v-if="tag.length > 20" :title="tag">
                    <a-checkable-tag
                      :closable="index !== 0"
                      @close="handleClose(tag)"
                    >
                      {{ `${tag.slice(0, 20)}...` }}
                    </a-checkable-tag>
                  </a-tooltip>
                  <a-checkable-tag
                    v-else
                    :closable="index >= 18"
                    @close="handleClose(tag)"
                    :checked="selectedTags.indexOf(tag) > -1"
                    @change="(checked) => handleChange(tag, checked)"
                  >
                    {{ tag }}
                  </a-checkable-tag>
                </template>
                <div>
                  <a-input
                    v-if="inputVisible"
                    ref="inputRef"
                    v-model:value="inputValue"
                    type="text"
                    size="small"
                    :style="{ width: '78px' }"
                    @blur="handleInputConfirm"
                    @keyup.enter="handleInputConfirm"
                  />
                  <a-tag
                    v-else
                    style="background: #fff; border-style: dashed"
                    @click="showInput"
                  >
                    <plus-outlined />
                    请输入新的标签
                  </a-tag>
                </div>
              </a-form-item>
            </div>
            <div class="con-item">
              <a-form-item>
                <a-alert
                  message="注：企业荣誉影响贷款额度,若荣誉多即可多选，如果企业荣誉没有可以自定义添加"
                  type="error"
                />
              </a-form-item>
            </div>
          </div>
        </div>
        <div class="ec-box">
          <div class="title">
            <p>企业认证-法定代表人信息</p>
          </div>
          <div class="content">
            <div class="con-item">
              <a-form-item label="法定代表人姓名">
                <a-input
                  v-model:value="legalName"
                  :disabled="isAuth === 'Y' ? true : false"
                  placeholder="请输入法定代表人姓名"
                />
              </a-form-item>
            </div>
            <div class="con-item">
              <a-form-item label="法定代表人身份证号码">
                <a-input
                  v-model:value="legalCard"
                  :disabled="isAuth === 'Y' ? true : false"
                  placeholder="请输入法定代表人身份证号码"
                />
              </a-form-item>
            </div>
            <div class="con-item">
              <a-form-item label="法定代表人手机号码">
                <a-input
                  v-model:value="legalPhone"
                  :disabled="isAuth === 'Y' ? true : false"
                  placeholder="请输入法定代表人手机号码"
                />
              </a-form-item>
            </div>
            <div class="con-item">
              <a-form-item label="法定代表人邮箱">
                <a-input
                  v-model:value="legalEmail"
                  :disabled="isAuth === 'Y' ? true : false"
                  placeholder="请输入法定代表人邮箱"
                />
              </a-form-item>
            </div>

            <div class="con-item">
              <a-form-item label="企业成立日期">
                <a-date-picker
                  v-model:value="regDate"
                  :disabled="isAuth === 'Y' ? true : false"
                  :format="dateFormat"
                  @change="onPickRegDate"
                  style="width: 100%;"
                  placeholder="请选择注册日期"
                />
              </a-form-item>
            </div>

            <div
              class="con-item"
              v-if="isAuth === 'Y' && isUploadImg == 'true' ? false : true"
            >
              <a-form-item label="验证码">
                <div class="wrap-input">
                  <a-input
                    v-model:value="verCode"
                    style="width: 50%"
                    placeholder="请输入验证码"
                  ></a-input>
                  <input
                    class="input2"
                    type="button"
                    @click="sendVerCode()"
                    :value="curCounttext"
                  />
                </div>
              </a-form-item>
            </div>
          </div>
        </div>
        <div class="ec-box" v-if="isAuth === 'N' || isUploadImg == 'false'">
          <div class="contents">
            <a-button @click="onNext">下一步</a-button>
          </div>
        </div>
      </div>
      <div v-if="step2">
        <div class="ec-box">
          <div class="title">
            <p>企业认证-附件信息</p>
          </div>
          <div class="content">
            <div class="con-item-download" @click="downloadFile">
              <a>企业查询授权书下载</a>
              <div style="color: red;margin-top: 10px;">
                需先委托书，填写后再进行上传（委托书格式不要修改）
              </div>
            </div>
            <div class="con-item con-item-upload" style="margin: 0 auto 20px;">
              <a-upload
                name="file"
                :action="$uploadApi"
                :data="{
                  FFILE: fileList1,
                  IDCARD_FRONT: fileListimg1,
                  IDCARD_BACK: fileListimg2,
                  BUSINESS_LICENSE: fileListimg3,
                  ENTERPRISE_ID: enterprise_id,
                  FILE_ADDR: detail.FILE_ADDR,
                  IDCARD_FRONT_ADDR: detail.IDCARD_FRONT_ADDR,
                  IDCARD_BACK_ADDR: detail.IDCARD_BACK_ADDR,
                  BUSINESS_LICENSE_ADDR: detail.BUSINESS_LICENSE_ADDR,
                }"
                @change="onUpload"
                :before-upload="beforeUpload1"
              >
                <a-button
                  v-if="
                    detail.FILE_ADDR != 'undefined' &&
                      detail.FILE_ADDR != undefined &&
                      detail.FILE_ADDR != ''
                  "
                >
                  <upload-outlined></upload-outlined>
                  更新企业查询授权书
                </a-button>
                <a-button v-else>
                  <upload-outlined></upload-outlined>
                  请上传企业查询授权书
                </a-button>
              </a-upload>
            </div>
            <!-- <div class="con-item con-item-upload">
                <a-upload
                    name="file2"
                    :action="$uploadApi"
                    :data="{ FFILE: fileList2, FILE_PROPERTY: '附件', FILE_NAME: fileListName2, ENTERPRISE_ID: enterprise_id }"
                    :headers="headers"
                    @change="onUpload"
                    :before-upload="beforeUpload2"
                >
                    <a-button>
                    <upload-outlined></upload-outlined>
                    请上传资产负债表
                    </a-button>
                </a-upload>
            </div>
            <div class="con-item con-item-upload">
                <a-upload
                    name="file3"
                    :action="$uploadApi"
                    :data="{ FFILE: fileList3, FILE_PROPERTY: '附件', FILE_NAME: fileListName3, ENTERPRISE_ID: enterprise_id }"
                    :headers="headers"
                    @change="onUpload"
                    :before-upload="beforeUpload3"
                >
                    <a-button>
                    <upload-outlined></upload-outlined>
                    请上传利润表
                    </a-button>
                </a-upload>
            </div>
            <div class="con-item con-item-upload">
                <a-upload
                    name="file4"
                    :action="$uploadApi"
                    :data="{ FFILE: fileList4, FILE_PROPERTY: '附件', FILE_NAME: fileListName4, ENTERPRISE_ID: enterprise_id }"
                    :headers="headers"
                    @change="onUpload"
                    :before-upload="beforeUpload4"
                >
                    <a-button>
                    <upload-outlined></upload-outlined>
                    请上传现金流量表
                    </a-button>
                </a-upload>
            </div> -->
          </div>
        </div>
        <div class="ec-box">
          <div class="title">
            <p>企业认证-证件信息</p>
          </div>
          <div class="content">
            <div class="con-item con-item-uploadimg">
              <a-upload
                v-model:file-list="fileList5"
                name="avatar"
                list-type="picture-card"
                class="avatar-uploader"
                :data="{
                  FFILE: fileList1,
                  IDCARD_FRONT: fileListimg1,
                  IDCARD_BACK: fileListimg2,
                  BUSINESS_LICENSE: fileListimg3,
                  ENTERPRISE_ID: enterprise_id,
                  FILE_ADDR: detail.FILE_ADDR,
                  IDCARD_FRONT_ADDR: detail.IDCARD_FRONT_ADDR,
                  IDCARD_BACK_ADDR: detail.IDCARD_BACK_ADDR,
                  BUSINESS_LICENSE_ADDR: detail.BUSINESS_LICENSE_ADDR,
                }"
                :show-upload-list="false"
                :action="$uploadApi"
                :before-upload="beforeUpload5"
                @change="onUpload($event, 0)"
              >
                <img
                  style="width: 238px;height: 195px"
                  v-if="
                    detail.IDCARD_FRONT_ADDR != 'undefined' &&
                      detail.IDCARD_FRONT_ADDR != undefined &&
                      detail.IDCARD_FRONT_ADDR != ''
                  "
                  :src="$baseUrl + '/' + detail.IDCARD_FRONT_ADDR"
                  alt="avatar"
                />
                <div v-else>
                  <loading-outlined v-if="loading"></loading-outlined>
                  <plus-outlined v-else></plus-outlined>
                  <div class="ant-upload-text">请上传身份证正面</div>
                </div>
              </a-upload>
            </div>
            <div class="con-item con-item-uploadimg">
              <a-upload
                list-type="picture-card"
                :action="$uploadApi"
                :show-upload-list="false"
                :data="{
                  FFILE: fileList1,
                  IDCARD_FRONT: fileListimg1,
                  IDCARD_BACK: fileListimg2,
                  BUSINESS_LICENSE: fileListimg3,
                  ENTERPRISE_ID: enterprise_id,
                  FILE_ADDR: detail.FILE_ADDR,
                  IDCARD_FRONT_ADDR: detail.IDCARD_FRONT_ADDR,
                  IDCARD_BACK_ADDR: detail.IDCARD_BACK_ADDR,
                  BUSINESS_LICENSE_ADDR: detail.BUSINESS_LICENSE_ADDR,
                }"
                @change="onUpload($event, 1)"
                :before-upload="beforeUpload6"
              >
                <img
                  style="width: 238px;height: 195px"
                  v-if="
                    detail.IDCARD_BACK_ADDR != 'undefined' &&
                      detail.IDCARD_BACK_ADDR != undefined &&
                      detail.IDCARD_BACK_ADDR != ''
                  "
                  :src="$baseUrl + '/' + detail.IDCARD_BACK_ADDR"
                  alt="avatar"
                />
                <div v-else>
                  <loading-outlined v-if="loading"></loading-outlined>
                  <plus-outlined v-else></plus-outlined>
                  <div class="ant-upload-text">请上传身份证反面</div>
                </div>
              </a-upload>
            </div>
            <div class="con-item con-item-uploadimg">
              <a-upload
                list-type="picture-card"
                :action="$uploadApi"
                :data="{
                  FFILE: fileList1,
                  IDCARD_FRONT: fileListimg1,
                  IDCARD_BACK: fileListimg2,
                  BUSINESS_LICENSE: fileListimg3,
                  ENTERPRISE_ID: enterprise_id,
                  FILE_ADDR: detail.FILE_ADDR,
                  IDCARD_FRONT_ADDR: detail.IDCARD_FRONT_ADDR,
                  IDCARD_BACK_ADDR: detail.IDCARD_BACK_ADDR,
                  BUSINESS_LICENSE_ADDR: detail.BUSINESS_LICENSE_ADDR,
                }"
                @change="onUpload($event, 2)"
                :show-upload-list="false"
                :before-upload="beforeUpload7"
              >
                <img
                  style="width: 238px;height: 195px"
                  v-if="
                    detail.BUSINESS_LICENSE_ADDR != 'undefined' &&
                      detail.BUSINESS_LICENSE_ADDR != undefined &&
                      detail.BUSINESS_LICENSE_ADDR != ''
                  "
                  :src="$baseUrl + '/' + detail.BUSINESS_LICENSE_ADDR"
                  alt="avatar"
                />
                <div v-else>
                  <loading-outlined v-if="loading"></loading-outlined>
                  <plus-outlined v-else></plus-outlined>
                  <div class="ant-upload-text">请上传营业执照</div>
                </div>
              </a-upload>
            </div>
          </div>
        </div>
        <div class="ec-box">
          <div class="contents">
            <a-form-item>
              <a-checkbox-group @change="onAgree">
                <a-checkbox value="1" name="type">我已阅读并同意</a-checkbox>
                <a @click="downAuthorize">《 企业信息查询授权书 》</a>及<a
                  @click="downCredit"
                  >《 征信服务授权书 》</a
                >
              </a-checkbox-group>
            </a-form-item>
            <a-button v-if="previous" @click="previousF">上一步</a-button>
            <a-button v-else @click="onSubmit">确认提交</a-button>
          </div>
        </div>
      </div>
    </a-form>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  ref,
  toRefs,
  nextTick,
  getCurrentInstance,
} from "vue";
import options from "@/assets/cityOptions";
import { list, downEnterfile } from "@/api/login/login";
import { auth, getLevels } from "@/api/other/other";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { mapGetters } from "vuex";

export default defineComponent({
  components: {
    UploadOutlined,
    PlusOutlined,
  },
  computed: {
    ...mapGetters(["userId", "orgId", "isAuth", "isUploadImg"]),
  },
  setup() {
    const datab = getCurrentInstance();
    const handleChange = (tag, checked) => {
      const { selectedTags } = state;
      const nextSelectedTags = checked
        ? [...selectedTags, tag]
        : selectedTags.filter((t) => t !== tag);
      console.log("You are interested in: ", nextSelectedTags);
      state.selectedTags = nextSelectedTags;
      let x = nextSelectedTags.join(",");
      datab.data.emphasisStr = x;
    };
    const inputRef = ref();
    const state = reactive({
      tags: [
        "高新技术认定企业",
        "科技型中小企业",
        "专精特新企业",
        "绿色信贷企业",
        "小巨人企业",
        "瞪羚企业",
        "省（市）新型研发机构",
        "国家标准定制企业",
        "省级标准定制企业",
        "行业标准定制企业",
        "政府奖补企业",
        "稳企稳岗企业",
        "创新创业大赛",
        "国家技术创新示范企业",
        "企业技术中心",
        "创新科技再贷款企业",
        "通过其他标准认证企业",
      ],
      inputVisible: false,
      inputValue: "",
      selectedTags: [],
    });

    const handleClose = (removedTag) => {
      const tags = state.tags.filter((tag) => tag !== removedTag);
      console.log(tags);
      state.tags = tags;
    };

    const showInput = () => {
      state.inputVisible = true;
      nextTick(() => {
        inputRef.value.focus();
      });
    };
    const handleInputConfirm = () => {
      const inputValue = state.inputValue;
      let tags = state.tags;
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
      }
      console.log(tags);
      Object.assign(state, {
        tags,
        inputVisible: false,
        inputValue: "",
      });
    };
    // const rules = {
    //   enterpriseName: [
    //     {
    //       required: true,
    //       message: "请输入企业名称",
    //       trigger: "blur",
    //     },
    //   ],
    //   creditCode: [
    //     {
    //       required: true,
    //       message: "请输入统一社会信用代码",
    //       trigger: "blur",
    //     },
    //   ],
    //   hangyeleixing: [
    //     {
    //       required: true,
    //       message: "请选择企业类型",
    //       trigger: "change",
    //     },
    //   ],
    //   hangyeleibie: [
    //     {
    //       required: true,
    //       message: "请选择行业类型",
    //       trigger: "change",
    //     },
    //   ],
    //   legalOrg: [
    //     {
    //       required: true,
    //       message: "请输入登记机关",
    //       trigger: "blur",
    //     },
    //   ],
    //   area: [
    //     {
    //       required: true,
    //       message: "请选择登记机关所在区域",
    //       trigger: "change",
    //     },
    //   ],
    //   qiyeleixing: [
    //     {
    //       required: true,
    //       message: "请选择行业归属",
    //       trigger: "change",
    //     },
    //   ],
    //   businessPlace: [
    //     {
    //       required: true,
    //       message: "请输入经营场所",
    //       trigger: "blur",
    //     },
    //   ],
    //   capital: [
    //     {
    //       required: true,
    //       message: "请输入注册资本(万元)",
    //       trigger: "blur",
    //     },
    //   ],
    // };
    return {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 16,
      },
      options,
      headers: {
        authorization: "authorization-text",
      },
      ...toRefs(state),
      handleClose,
      showInput,
      handleInputConfirm,
      inputRef,
      checked1: ref(false),
      handleChange,
    };
  },
  data() {
    const fileList2 = [];
    return {
      detail: {},
      isAdd: true,
      fileList1: [],
      fileList2: [],
      fileList3: [],
      fileList4: [],
      fileList5: [],
      fileListName1: "",
      fileListName2: "",
      fileListName3: "",
      fileListName4: "",
      fileListimg1: [],
      fileListimg2: [],
      fileListimg3: [],
      fileListImgName1: "",
      fileListImgName2: "",
      fileListImgName3: "",
      step1: true,
      step2: false,
      dateFormat: "YYYY-MM-DD",
      enterpriseName: "",
      creditCode: "",
      hylb: [],
      industryType: [],
      enterpriseType: [],
      area: [],
      legalName: undefined,
      legalCard: undefined,
      legalPhone: undefined,
      legalEmail: undefined,
      legalOrg: undefined,
      regDate: "",
      compositionForm: "",
      businessPlace: "",
      businessScope: "",
      collateral: "",
      capital: "",
      collaRemark: "",
      enterprise_id: "",
      isAgree: false,
      qiyeleixing: undefined,
      hangyeleixing: undefined,
      hangyeleibie: undefined,
      InterValObj: undefined, //timer变量，控制时间
      count: 60, //间隔函数，1秒执行
      curCount: 0, //当前剩余秒数
      curCounttext: "获取验证码",
      verCode: undefined,
      d: true,
      previous: false,
      //新增重点企业支持
      emphasis: [],
      emphasisStr: "",
    };
  },
  methods: {
    getFileList(id) {
      list({ ENTERPRISE_ID: this.enterprise_id }).then((res) => {
        console.log(res);
      });
    },
    //下载
    downloadFile() {
      // downEnterfile({}).then(res=>{
      //
      // })
      // window.location.href = this.$downExcel
      console.log("下载");
      // var url='http://122.9.42.246:8052/uploadFiles/file/20210628/992a2b8a210b439794dbb4fb8af2e559.png'
      //  fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
      //      const a = document.createElement('a')
      //      a.href = URL.createObjectURL(blob)
      //      console.log(a.href)
      //      a.download = ''  // 下载文件的名字
      //      document.body.appendChild(a)
      //      a.click()
      //  })

      // console.log(1212121)
      // var request = new XMLHttpRequest();
      // request.responseType = "blob";
      // let fileUrl = "https://img1.baidu.com/it/u=1157961245,4086073074&fm=26&fmt=auto&gp=0.jpg"; // 文件路径
      // request.open("GET", fileUrl);
      // request.onload = function () {
      //     var url = window.URL.createObjectURL(this.response);
      //     var a = document.createElement("a");
      //     document.body.appendChild(a);
      //     a.href = url;
      //     a.download = "a.jpg";
      //     a.click();
      // }
      // request.send();
      // var url2 = "https://seopic.699pic.com/photo/40191/3608.jpg_wh1200.jpg";
      // window.location.href = url2;
      window.location = "/tpls/企业信息采集及信息查询授权委托书.doc";
    },
    downAuthorize() {
      window.location = "/tpls/个体工商户信息采集及信息查询授权委托书.doc";
    },

    downCredit() {
      window.location = "/tpls/征信协议.docx";
    },
    onHangyeleixing(e) {
      this.hangyeleixing = e;
    },
    onQiyeleixing(e) {
      this.qiyeleixing = e;
    },
    onUpload(info, index) {
      var that = this;
      console.log(info, "上传", index);
      if (info.file.response) {
        if (info.file.response.result === "success") {
          this.$message.success("上传成功！");
          this.getOrgDetail();
        } else {
          this.$message.success("上传失败！");
        }
      }
    },
    beforeUpload1(e) {
      this.fileListName1 = e.name;
      this.fileList1 = e;
    },
    beforeUpload2(e) {
      this.fileListName2 = e.name;
      this.fileList2 = e;
    },
    beforeUpload3(e) {
      this.fileListName3 = e.name;
      this.fileList3 = e;
    },
    beforeUpload4(e) {
      this.fileListName4 = e.name;
      this.fileList4 = e;
    },
    beforeUpload5(e) {
      console.log(e);
      this.fileListImgName1 = e.name;
      this.fileListimg1 = e;
      this.fileList5.push(e);
    },
    beforeUpload6(e) {
      this.fileListImgName2 = e.name;
      this.fileListimg2 = e;
    },
    beforeUpload7(e) {
      this.fileListImgName3 = e.name;
      this.fileListimg3 = e;
    },

    //验证码
    sendVerCode() {
      if (!this.d) {
        this.$message.warning("验证码正在发送中请等待！！");
        return false;
      }
      if (this.legalPhone === undefined || this.legalPhone === "") {
        this.$message.info("请输入法定代表人手机号码");
        return false;
      }
      if (!this.isPoneAvailable(this.legalPhone)) {
        this.$message.error("法定代表人联系电话格式不正确!");
        return false;
      }
      var params = {
        phone: this.legalPhone,
        type: "1",
        platformName: "龙哈智能产融综合服务平台",
      };
      this.$store.dispatch("getVerCode", params).then((res) => {
        if (res.result === "success") {
          console.log(res);
          this.$message.success("发送成功");
          this.d = false;
          this.curCount = this.count;
          // $("#btnSendCode").removeAttr("onclick");
          this.curCounttext = this.curCount + "秒后重新发送";
          this.InterValObj = window.setInterval(this.SetRemainTime, 1000); //启动计时器，1秒执行一次
        } else {
          // debugger
          let msg = res.message;
          this.$message.error(msg);
        }
      });
    },
    SetRemainTime() {
      if (this.curCount === 1) {
        window.clearInterval(this.InterValObj); //停止计时器
        this.d = true;
        this.curCounttext = "获取验证码";
      } else {
        this.curCount--;
        // eslint-disable-next-line no-undef
        this.curCounttext = this.curCount + "秒后重新发送";
      }
    },
    /**
     * 验证手机号
     * @param str
     * @returns {boolean}
     */
    isPoneAvailable(str) {
      var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!myreg.test(str)) {
        return false;
      } else {
        return true;
      }
    },
    isValid() {
      if (this.verCode === "" || this.verCode === undefined) {
        this.$message.info("请输入验证码");
        return false;
      } else if (this.enterpriseName === "" || this.enterpriseName === undefined) {
        this.$message.info("请输入企业名称");
        return false;
      } else if (this.creditCode === "" || this.creditCode === undefined) {
        this.$message.info("请输入统一社会信用代码");
        return false;
      } else if (this.hangyeleibie === undefined || this.hangyeleibie === "") {
        this.$message.info("请选择行业类型");
        return false;
      } else if (this.hangyeleixing === undefined || this.hangyeleixing === "") {
        this.$message.info("请选择企业类型");
        return false;
      } else if (this.legalOrg === "" || this.legalOrg === undefined) {
        this.$message.info("请输入登记机关");
        return false;
      } else if (this.area === undefined || this.area === "" ) {
        this.$message.info("请选择登记机关所在区域");
        return false;
      } else if (this.qiyeleixing === undefined || this.qiyeleixing === "" ) {
        this.$message.info("请选择行业归属");
        return false;
      } else if (this.businessPlace === "" || this.businessPlace === undefined) {
        this.$message.info("请输入经营场所");
        return false;
      } else if (this.capital === "" || this.capital === undefined) {
        this.$message.info("请输入注册资本(万元)");
        return false;
      } else if (this.legalName === undefined ||  this.legalName === "") {
        this.$message.info("请输入法定代表人姓名");
        return false;
      } else if (this.legalCard === undefined ||  this.legalCard === "") {
        this.$message.info("请输入法定代表人身份证号码");
        return false;
      } else if (this.legalPhone === undefined ||  this.legalPhone === "") {
        this.$message.info("请输入法定代表人手机号码");
        return false;
      } else {
        return true;
      }
    },
    onNext() {
      let isValid = this.isValid();
      if (isValid) {
        var params = {
          FILE_ADDR: "",
          IDCARD_FRONT_ADDR: "",
          IDCARD_BACK_ADDR: "",
          BUSINESS_LICENSE_ADDR: "",
          ENTERPRISE_NAME: this.enterpriseName,
          CREDIT_CODE: this.creditCode,
          INDUSTRY_TYPE: this.hangyeleixing,
          ENTERPRISE_TYPE: this.qiyeleixing,
          REGISAUTH_PROVINCE: this.area[0] ? this.area[0] : "",
          REGISAUTH_CITY: this.area[1] ? this.area[1] : "",
          REGISAUTH_DISTRICT: this.area[2] ? this.area[2] : "",
          LEGAL_PERSON_NAME: this.legalName,
          CERTIFICATE_NUM: this.legalCard,
          LEGAL_PERSON_PHONE: this.legalPhone,
          LEGAL_PERSON_EMAIL: this.legalEmail,
          REGISTRATION_AUTHORITY: this.legalOrg,
          ESTABLISHED_TIME: this.regDate,
          INDUSTRY_TYPE_NEW: this.hangyeleibie,
          BUSINESS_PLACE: this.businessPlace,
          BUSINESS_SCOPE: this.businessScope,
          COLLATERAL: this.collateral,
          COLLA_REMARK: this.collaRemark,
          REGIST_TYPE: "E",
          MEMBER_ID: this.userId,
          IS_AUTHENTICATION: this.isAuth,
          USER_ID: this.userId,
          REGISTERED_CAPITAL: this.capital,
          VERCODE: this.verCode,
          emphasis: this.emphasisStr,
          // emphasis: that1,
          tm: new Date().getTime(),
        };
        if (this.isAdd) {
          debugger;
          this.$store.dispatch("getEnterPriseAuth", params).then((res) => {
            if (res.result === "success") {
              console.log(res);
              this.enterprise_id = res.ENTERPRISE_ID;
              this.step1 = false;
              this.step2 = true;
              this.getOrgDetail();
            } else {
              this.$message.info(res.msg);
            }
          });
        } else {
          params.ENTERPRISE_ID = this.ENTERPRISE_ID;
          this.$store.dispatch("getEnterPriseAuthEdit", params).then((res) => {
            if (res.result === "success") {
              console.log(res);
              this.enterprise_id = res.ENTERPRISE_ID;
              this.step1 = false;
              this.step2 = true;
              this.getOrgDetail();
            } else {
              this.$message.info(res.msg);
            }
          });
        }
        // this.$store.dispatch('getEnterPriseAuthEdit', params).then(res => {
        //     if (res.result === 'success') {
        //         console.log(res)
        //         this.enterprise_id = res.ENTERPRISE_ID
        //         this.step1 = false
        //         this.step2 = true
        //         this.getFileList(res.ENTERPRISE_ID)
        //     } else {
        //         this.$message.info('系统异常，请稍后重试')
        //     }
        // })
      }
    },
    onPickRegDate(date, dateString) {
      this.regDate = dateString;
    },
    onAgree(e) {
      if (e.length == 0) {
        this.isAgree = false;
      } else {
        this.isAgree = true;
      }
    },
    //上一步
    previousF() {
      //返回上一个页面
      this.step1 = true;
      this.step2 = false;
      this.previous = false;
    },
    onSubmit() {
      var that = this;
      console.log(this.fileList1.length, this.detail.FILE_ADDR);
      console.log(this.fileListimg1.length, this.detail.IDCARD_FRONT_ADDR);
      console.log(this.fileListimg2.length, this.detail.IDCARD_BACK_ADDR);
      console.log(this.fileListimg3.length, this.detail.BUSINESS_LICENSE_ADDR);
      if (this.detail.FILE_ADDR == "undefined" || this.detail.FILE_ADDR == "") {
        this.$message.info("请上传企业查询授权书");
        return false;
      } else if (
        this.detail.IDCARD_FRONT_ADDR == "undefined" ||
        this.detail.IDCARD_FRONT_ADDR == ""
      ) {
        this.$message.info("请上传身份证正面");
        return false;
      } else if (
        this.detail.IDCARD_BACK_ADDR == "undefined" ||
        this.detail.IDCARD_BACK_ADDR == ""
      ) {
        this.$message.info("请上传身份证反面");
        return false;
      } else if (
        this.detail.BUSINESS_LICENSE_ADDR == "undefined" ||
        this.detail.BUSINESS_LICENSE_ADDR == ""
      ) {
        this.$message.info("请上传营业执照");
        return false;
      } else if (!this.isAgree) {
        this.$message.info("请勾选协议！");
        return false;
      }
      auth({
        ENTERPRISE_ID: that.ENTERPRISE_ID,
        tm: new Date().getTime(),
      }).then((res) => {
        console.log(res);
        if (res.result === "success") {
          console.log("123");
          this.$message.success("认证成功！");
          that.$store.commit("SET_ORGID", that.ENTERPRISE_ID);
          that.$store.commit("SET_ORG_NAME", that.enterpriseName);
          that.$store.commit("SET_ISUPLOADIMG", "true");
          that.$store.commit("SET_IS_AUTH", "Y");
          this.getOrgDetail();
          setTimeout(() => {
            this.$router.back();
          }, 1500);
        } else {
          this.previous = true;
          this.$message.error(res.msg);
        }
      });
      // this.$message.success('提交成功，请耐心等待审核！')
    },
    getOrgDetail() {
      this.$store
        .dispatch("getOrgDetail", {
          ENTERPRISE_ID: this.orgId,
          tm: new Date().getTime(),
          MEMBER_ID: this.userId,
        })
        .then((res) => {
          if (res.result === "success") {
            if (res.pd !== null) {
              console.log("pd  dengyu null  ");

              this.isAdd = false;
              let datas = res.pd;
              this.detail = res.pd;
              // this.fileList1 = [datas.FILE_ADDR]
              console.log(datas.FILE_ADDR);
              this.enterpriseName = datas.ENTERPRISE_NAME;
              this.creditCode = datas.CREDIT_CODE;
              this.ENTERPRISE_ID = datas.ENTERPRISE_ID;
              this.qiyeleixing = datas.ENTERPRISE_TYPE;
              this.hangyeleixing = datas.INDUSTRY_TYPE;
              this.area = [
                datas.REGISAUTH_PROVINCE,
                datas.REGISAUTH_CITY,
                datas.REGISAUTH_DISTRICT ? datas.REGISAUTH_DISTRICT : "",
              ];
              this.legalName = datas.LEGAL_PERSON_NAME;
              this.legalCard = datas.CERTIFICATE_NUM;
              this.legalPhone = datas.LEGAL_PERSON_PHONE;
              this.legalEmail = datas.LEGAL_PERSON_EMAIL;
              this.legalOrg = datas.REGISTRATION_AUTHORITY;
              this.capital = datas.REGISTERED_CAPITAL;
              this.regDate = datas.ESTABLISHED_TIME;
              this.hangyeleibie = datas.INDUSTRY_TYPE_NEW;
              this.businessPlace = datas.BUSINESS_PLACE;
              this.businessScope = datas.BUSINESS_SCOPE;
              this.collateral = datas.COLLATERAL;
              this.collaRemark = datas.COLLA_REMARK;
              this.emphasisStr = datas.ENTERPRISE_HONOR;
              this.emphasis = this.emphasisStr.split(",");
              this.selectedTags = datas.ENTERPRISE_HONOR.split(",");
              this.getFileList(datas.ENTERPRISE_ID);
            }
          } else {
            this.$message.error("服务器异常！");
          }
        });
    },
    // 获取数据字典
    getServiceItemList() {
      // 获取企业类型下拉选项列表
      this.$store
        .dispatch("getServiceItemList", {
          DICTIONARIES_ID: "d28d5566b2e541f18607bf7ce8af22e2",
          tm: new Date().getTime(),
        })
        .then((res) => {
          if (res.result === "success") {
            this.enterpriseType = res.list;
          }
        });
      // 获取行业类型下拉选项列表
      this.$store
        .dispatch("getServiceItemList", {
          DICTIONARIES_ID: "42d8cf819bc94d9987dee3c638d97800",
          tm: new Date().getTime(),
        })
        .then((res) => {
          if (res.result === "success") {
            this.industryType = res.list;
          }
        });
      getLevels({
        tm: new Date().getTime(),
        DICTIONARIES_ID: "8f776edab7944645b84a66ea8bee3a4e",
      }).then((res) => {
        if (res.result === "success") {
          this.hylb = res.list;
        }
      });
    },
  },
  created() {
    // if (this.orgId != null && this.orgId != '' && this.orgId != 'null' && this.orgId != undefined) {
    this.getOrgDetail();

    // }else{
    // }
    // 获取数据字典
    this.getServiceItemList();
  },
});
</script>

<style lang="less" scoped>
.main-box {
  width: @main-width-base;
  margin: 20px auto;

  .ec-box {
    padding: 15px;
    background-color: @color-ff;
    box-shadow: 0px 0px 10px #929292;
    border-radius: 5px;
    margin: 20px 0;
    position: relative;

    .title {
      font-size: @font-lg;
      padding: 0px 10px;
      padding-bottom: 15px;
      border-bottom: @border-base;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin-bottom: 0;
      }
    }

    .content {
      padding-top: 15px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .con-item {
        width: 49%;
      }

      .con-item-download {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;

        a {
          font-size: @font-lg;
          display: inline-block;
          text-decoration: underline;
          color: @color-ff;
          padding: 10px 60px;
          background: @color-blue; /* 标准语法 */
        }
      }

      .con-item-vercode {
        .ant-form-item {
          .ant-input {
            width: 55%;
          }

          .ant-btn {
            width: 40%;
            margin-left: 5%;
          }
        }
      }

      .con-item-upload {
        width: 20%;
        margin-bottom: 20px;

        ::v-deep(.ant-upload) {
          width: 100%;
        }

        ::v-deep(.ant-btn) {
          width: 100%;
        }
      }

      .con-item-uploadimg {
        width: 30%;

        ::v-deep(.ant-upload) {
          width: 240px;
          height: 195px;
        }

        ::v-deep(.ant-upload-list-item) {
          width: 240px;
          height: 195px;
        }

        ::v-deep(.ant-upload-list-picture-card-container) {
          height: 195px;
        }
      }
    }

    .contents {
      text-align: center;

      ::v-deep(.ant-form-item-control-wrapper) {
        margin: 0 auto;
        text-align: center;
      }

      .ant-btn {
        padding: 0 120px;
        height: 40px;
        color: @color-ff;
        background: @color-blue; /* 标准语法 */
      }
    }
  }

  .ec-box::before {
    content: "";
    position: absolute;
    left: -3px;
    top: 10px;
    width: 6px;
    height: 40px;
    background: @color-blue; /* 标准语法 */
  }

  .input2 {
    width: 30%;
    float: right;
    margin-left: 1%;
    padding: 0 !important;
    font-size: 17px;
    line-height: 1.2;
    display: block;
    background: #fff;
    height: 38px;
    border-radius: 5px;
    border: none;
    background-color: rgba(100, 116, 223, 0.97);
    color: #fff;
    cursor: pointer;
    margin-right: 15%;
  }
}
</style>
